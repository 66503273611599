import ky from 'ky';
import { getAppConfig } from '@/config/env';
import { customHeaders } from '@/config/custom-headers';
import { BaseCVResponse, ImagePayload } from './common';

// Sky id format as defined by the API
export type TimeOfDay = 'daylight' | 'sunset';
const allowedSkyCategories = [
  'daylight',
  'daylight_cloudy',
  'sunset',
  'sunset_cloudy',
] as const;
export type SkyCategory = (typeof allowedSkyCategories)[number];
type SkyId = `sky-${SkyCategory}-${number}`;

const skies: Array<SkyId> = [
  'sky-daylight-1',
  'sky-daylight-2',
  'sky-daylight-3',
  'sky-daylight-4',
  'sky-daylight-5',
  'sky-daylight_cloudy-1',
  'sky-daylight_cloudy-2',
  'sky-daylight_cloudy-3',
  'sky-daylight_cloudy-4',
  'sky-daylight_cloudy-5',
  'sky-sunset-1',
  'sky-sunset-2',
  'sky-sunset-3',
  'sky-sunset-4',
  'sky-sunset-5',
  'sky-sunset-6',
  'sky-sunset_cloudy-1',
  'sky-sunset_cloudy-2',
  'sky-sunset_cloudy-3',
  'sky-sunset_cloudy-4',
  'sky-sunset_cloudy-5',
  'sky-sunset_cloudy-6',
  'sky-sunset_cloudy-7',
  'sky-sunset_cloudy-8',
  'sky-sunset_cloudy-9',
  'sky-sunset_cloudy-10',
] as const;

// Formatting skies to be used accross the app
export type SkyType = 'clear' | 'cloudy';
export type Sky = {
  id: SkyId;
  skyCategory: SkyCategory;
  skyNumber: number;
  timeOfDay: TimeOfDay;
  skyType: SkyType;
  skyS3Key: string;
  previewS3Key: string;
};

function isKnownCategory(category: string): category is SkyCategory {
  return allowedSkyCategories.includes(category as SkyCategory);
}

export const SKIES_LIST = skies.map((id): Sky => {
  const [_, skyCategory, variantId] = id.split('-');
  const [timeOfDay, skyType] = skyCategory.split('_');

  if (!isKnownCategory(skyCategory)) {
    throw new Error(`Invalid sky category: ${skyCategory}`);
  }

  return {
    id,
    skyCategory: skyCategory,
    skyNumber: parseInt(variantId),
    timeOfDay: timeOfDay === 'sunset' ? 'sunset' : 'daylight',
    skyType: skyType === 'cloudy' ? 'cloudy' : 'clear',
    skyS3Key: `webapp/skies/${id}.jpg`,
    previewS3Key: `webapp/skies/previews/${id}.jpg`,
  };
});

export type SkyReplacementServicePayload = {
  input: ImagePayload & {
    mediaUuid: string;
  };
  settings: {
    category: SkyCategory;
    id: number;
  };
};

export type SkyReplacementResponse = BaseCVResponse;

export async function callSkyReplacementService(
  token: string,
  workspaceId: string | null,
  payload: SkyReplacementServicePayload
) {
  const { skyReplacementUrl } = getAppConfig();

  const response = await ky
    .post(skyReplacementUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
        [customHeaders.workspaceId]: workspaceId ?? 'None',
      },
      json: payload,
      timeout: false,
    })
    .then((val) => val.json<SkyReplacementResponse>());

  return response;
}
